import React, { useState } from "react";
import { Button, TextField, Typography, Box, Container } from "@mui/material";

const OnWearTestComponent = ({ sdk, environment }) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [clickId, setClickId] = useState("");

  const handleInputChange = (event) => {
    setClickId(event.target.value);
  };

  const handleInitializeSDK = () => {
    // Initialize the SDK
    if (window[sdk]) {
      window[sdk].init();
      setIsInitialized(true);
    }
  };

  const handleTrackClick = () => {
    // Track a click event
    if (window[sdk]) {
      window[sdk].track("CLICK", { clickId });
    }
  };

  const handleTrackImpression = () => {
    // Track an impression event
    if (window[sdk]) {
      window[sdk].track("IMPRESSION", { clickId });
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        OnWear {environment} SDK Test
      </Typography>

      {!isInitialized && (
        <Button
          variant="contained"
          color="primary"
          onClick={handleInitializeSDK}
        >
          Initialize SDK
        </Button>
      )}

      {isInitialized && (
        <>
          <Box component="div" mb={4}>
            <Typography variant="h6" gutterBottom>
              Click Event
            </Typography>
            <TextField
              label="Click ID"
              name="clickId"
              value={clickId}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleTrackClick}
            >
              Track Click Event
            </Button>
          </Box>

          <Box component="div">
            <Typography variant="h6" gutterBottom>
              Impression Event
            </Typography>
            <TextField
              label="Click ID"
              name="clickId"
              value={clickId}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleTrackImpression}
            >
              Track Impression Event
            </Button>
          </Box>
        </>
      )}
    </Container>
  );
};

export default OnWearTestComponent;
