import React from "react";
import OnWearTestComponent from "./OnWearTestComponent";

const OnWearTest = () => {
  return (
    <div>
      <OnWearTestComponent sdk="onwearLocalSDK" environment="Local" />
      <OnWearTestComponent sdk="onwearDevSDK" environment="Dev" />
      <OnWearTestComponent sdk="onwearSDK" environment="Prod (Not Yet)" />
    </div>
  );
};

export default OnWearTest;
