import React, { useState, useCallback } from "react";
import { axiosClient } from "../APIClient/APIClient";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Grid,
  Alert,
} from "@mui/material";
import { useDropzone } from "react-dropzone";

const isValidUUID = (uuid) =>
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(uuid);

const FaceSwapTest = () => {
  const [extractImage, setExtractImage] = useState(null);
  const [extractImageName, setExtractImageName] = useState("");
  const [extractImageSize, setExtractImageSize] = useState(0);
  const [extractGender, setExtractGender] = useState("MAN");
  const [extractResponse, setExtractResponse] = useState(null);
  const [isExtractLoading, setIsExtractLoading] = useState(false);
  const [extractDuration, setExtractDuration] = useState(null);

  const [swapStyleId, setSwapStyleId] = useState("");
  const [swapResponse, setSwapResponse] = useState(null);
  const [isSwapLoading, setIsSwapLoading] = useState(false);
  const [swapDuration, setSwapDuration] = useState(null);

  const [creatorGender, setCreatorGender] = useState("MAN");
  const [creatorPage, setCreatorPage] = useState(0);
  const [creatorPageSize, setCreatorPageSize] = useState(5);
  const [creatorResponse, setCreatorResponse] = useState(null);

  const [creatorId, setCreatorId] = useState("");
  const [creatorIdPage, setCreatorIdPage] = useState(0);
  const [creatorIdPageSize, setCreatorIdPageSize] = useState(10);
  const [creatorIdResponse, setCreatorIdResponse] = useState(null);

  const handleExtractFace = async (e) => {
    e.preventDefault();
    setIsExtractLoading(true);
    const start = Date.now();
    const formData = new FormData();
    formData.append("imageFile", extractImage);
    formData.append("gender", extractGender);
    try {
      const response = await axiosClient.post(
        "/api/v1/face-swap/extract",
        formData
      );
      setExtractResponse(response.data);
      setExtractDuration(Date.now() - start);
    } catch (error) {
      console.error(error);
    } finally {
      setIsExtractLoading(false);
    }
  };

  const handleImageUpload = (files) => {
    const file = files[0];
    setExtractImage(file);
    setExtractImageName(file ? file.name : "");
    setExtractImageSize(file ? file.size : 0);
  };

  const onDrop = useCallback((acceptedFiles) => {
    handleImageUpload(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleSwap = async (e) => {
    e.preventDefault();
    setIsSwapLoading(true);
    const start = Date.now();
    try {
      const response = await axiosClient.get("/api/v1/face-swap/swap", {
        params: {
          identifier: extractResponse?.data?.identifier,
          styleId: swapStyleId,
        },
      });
      setSwapResponse(response.data);
      setSwapDuration(Date.now() - start);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSwapLoading(false);
    }
  };

  const handleGetCreatorStyles = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosClient.get(
        "/api/v1/face-swap/creator/styles",
        {
          params: {
            gender: creatorGender,
            page: creatorPage,
            pageSize: creatorPageSize,
          },
        }
      );
      setCreatorResponse(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetCreatorStylesById = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosClient.get(
        `/api/v1/face-swap/creator/${creatorId}/styles`,
        {
          params: {
            page: creatorIdPage,
            size: creatorIdPageSize,
          },
        }
      );
      setCreatorIdResponse(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>
        FaceSwapTest
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box component="form" onSubmit={handleExtractFace} sx={{ mb: 4 }}>
            <Typography variant="h6" gutterBottom>
              1. 얼굴 이미지 업로드하기
            </Typography>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Gender</InputLabel>
              <Select
                value={extractGender}
                onChange={(e) => setExtractGender(e.target.value)}
              >
                <MenuItem value="MAN">MAN</MenuItem>
                <MenuItem value="WOMAN">WOMAN</MenuItem>
                <MenuItem value="null">null</MenuItem>
              </Select>
            </FormControl>
            <Box
              {...getRootProps()}
              sx={{
                border: "2px dashed #ccc",
                borderRadius: "4px",
                p: 2,
                textAlign: "center",
                mb: 2,
                cursor: "pointer",
              }}
            >
              <input {...getInputProps()} />
              <Typography>
                드래그 앤 드롭 또는 클릭하여 얼굴 이미지 업로드
              </Typography>
            </Box>
            {extractImageName && (
              <Typography variant="body2" sx={{ mb: 2 }}>
                Uploaded: {extractImageName} (
                {(extractImageSize / 1024).toFixed(2)} KB)
              </Typography>
            )}
            {extractImage && (
              <Box
                component="img"
                src={URL.createObjectURL(extractImage)}
                alt="Uploaded Image"
                sx={{ width: "100%", mb: 2, borderRadius: 8 }}
              />
            )}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={!extractImage || isExtractLoading}
            >
              {isExtractLoading ? (
                <CircularProgress size={24} />
              ) : (
                "Extract Face"
              )}
            </Button>
            {!extractImage && (
              <Alert severity="info" sx={{ mt: 2 }}>
                얼굴 이미지를 먼저 업로드 해주세요!
              </Alert>
            )}
            {extractResponse && (
              <>
                {extractResponse.data.isSuccess ? (
                  <Box component="pre" sx={{ mt: 2 }}>
                    {JSON.stringify(extractResponse, null, 2)}
                  </Box>
                ) : (
                  <Alert severity="error" sx={{ mt: 2 }}>
                    요청이 실패했습니다. 다시 시도해 주세요.
                  </Alert>
                )}
                {extractDuration && (
                  <Typography variant="body2" sx={{ mt: 2 }}>
                    API 호출 시간: {extractDuration / 1000} 초
                  </Typography>
                )}
              </>
            )}
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            component="form"
            onSubmit={handleGetCreatorStyles}
            sx={{ mb: 4 }}
          >
            <Typography variant="h6" gutterBottom>
              2. 크리에이터 착장 가져오기
            </Typography>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Gender</InputLabel>
              <Select
                value={creatorGender}
                onChange={(e) => setCreatorGender(e.target.value)}
              >
                <MenuItem value="MAN">MAN</MenuItem>
                <MenuItem value="WOMAN">WOMAN</MenuItem>
                <MenuItem value="null">null</MenuItem>
              </Select>
            </FormControl>
            <TextField
              fullWidth
              type="number"
              label="Page"
              value={creatorPage}
              onChange={(e) => setCreatorPage(Number(e.target.value))}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              type="number"
              label="Page Size"
              value={creatorPageSize}
              onChange={(e) => setCreatorPageSize(Number(e.target.value))}
              sx={{ mb: 2 }}
            />
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Get Styles
            </Button>
            {creatorResponse && (
              <Grid container spacing={2} sx={{ mt: 2 }}>
                {creatorResponse.data.creatorAndStyleOutputs.map((creator) =>
                  creator.videoStyleOutputs.map((style) => (
                    <Grid item xs={12} sm={6} md={4} key={style.id}>
                      <Box sx={{ textAlign: "center" }}>
                        <img
                          src={style.videoStyleImagesOutput.normalUrl}
                          alt={`Style ${style.id}`}
                          style={{ width: "100%", borderRadius: 8 }}
                        />
                        <Typography>Style ID: {style.id}</Typography>
                      </Box>
                    </Grid>
                  ))
                )}
              </Grid>
            )}
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box component="form" onSubmit={handleSwap} sx={{ mb: 4 }}>
            <Typography variant="h6" gutterBottom>
              3. Swap Face
            </Typography>
            <TextField
              fullWidth
              type="text"
              label="1번 결과의 Identifier (1번 완료 시 자동 입력됩니다)"
              value={extractResponse?.data?.identifier || ""}
              InputProps={{
                readOnly: true,
              }}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              type="text"
              label="2번 결과의 Style ID"
              value={swapStyleId}
              onChange={(e) => setSwapStyleId(e.target.value)}
              sx={{ mb: 2 }}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={
                isSwapLoading ||
                !extractResponse?.data?.identifier ||
                !isValidUUID(extractResponse?.data?.identifier)
              }
            >
              {isSwapLoading ? <CircularProgress size={24} /> : "Swap Face"}
            </Button>
            {(!extractResponse?.data?.identifier ||
              !isValidUUID(extractResponse?.data?.identifier)) && (
              <Alert severity="info" sx={{ mt: 2 }}>
                1번 요청을 먼저 완료해 주세요.
              </Alert>
            )}
            {swapResponse && (
              <>
                {swapResponse.data.isSuccess ? (
                  <Box sx={{ mt: 2, textAlign: "center" }}>
                    <img
                      src={swapResponse.data.resultUrl}
                      alt="Swap Result"
                      style={{ width: "100%", borderRadius: 8 }}
                    />
                  </Box>
                ) : (
                  <Alert severity="error" sx={{ mt: 2 }}>
                    얼굴 교체에 실패했습니다. 다시 시도해 주세요.
                  </Alert>
                )}
                {swapDuration && (
                  <Typography variant="body2" sx={{ mt: 2 }}>
                    API 호출 시간: {swapDuration / 1000} 초
                  </Typography>
                )}
              </>
            )}
          </Box>

          <Typography
            variant="body1"
            color="error"
            sx={{ fontWeight: "bold", mt: 4, textAlign: "center" }}
          >
            3번은 같은 사진에 대해서는 1회만 처리됨.
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            component="form"
            onSubmit={handleGetCreatorStylesById}
            sx={{ mb: 4 }}
          >
            <Typography variant="h6" gutterBottom>
              Get Creator Styles by Creator ID
            </Typography>
            <TextField
              fullWidth
              type="text"
              label="Creator ID"
              value={creatorId}
              onChange={(e) => setCreatorId(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              type="number"
              label="Page"
              value={creatorIdPage}
              onChange={(e) => setCreatorIdPage(Number(e.target.value))}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              type="number"
              label="Page Size"
              value={creatorIdPageSize}
              onChange={(e) => setCreatorIdPageSize(Number(e.target.value))}
              sx={{ mb: 2 }}
            />
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Get Styles by ID
            </Button>
            {creatorIdResponse && (
              <Grid container spacing={2} sx={{ mt: 2 }}>
                {creatorIdResponse.data.creatorAndStyleOutput.videoStyleOutputs.map(
                  (style) => (
                    <Grid item xs={12} sm={6} md={4} key={style.id}>
                      <Box sx={{ textAlign: "center" }}>
                        <img
                          src={style.videoStyleImagesOutput.normalUrl}
                          alt={`Style ${style.id}`}
                          style={{ width: "100%", borderRadius: 8 }}
                        />
                        <Typography>Style ID: {style.id}</Typography>
                      </Box>
                    </Grid>
                  )
                )}
              </Grid>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FaceSwapTest;
