import { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  Divider,
  Stack,
} from "@mui/material";

function TestComponent({ sdk, sdkName }) {
  const [advertiserId, setAdvertiserId] = useState("");
  const [isInit, setIsInit] = useState(false);
  const [viewContentBody, setViewContentBody] = useState({
    productId: null,
    productName: null,
  });
  const [addToCartBody, setAddToCartBody] = useState({
    productId: null,
    productName: null,
  });
  const [purchaseBody, setPurchaseBody] = useState({
    product: null,
    transactionId: null,
  });

  const initOnClick = () => {
    window[sdk].init(advertiserId);
    console.log("init with id: ", advertiserId);
    setIsInit(true);
  };

  const pageViewOnClick = () => {
    window[sdk].track("page-view");
  };

  const viewContentOnClick = () => {
    window[sdk].track("view-content", {
      productId: viewContentBody.productId,
      productName: viewContentBody.productName,
    });
  };

  const addToCartOnClick = () => {
    window[sdk].track("add-to-cart", addToCartBody);
  };

  const purchaseOnClick = () => {
    window[sdk].track("purchase", purchaseBody);
  };

  const handleChange = (setter) => (prop) => (event) => {
    setter((prev) => ({ ...prev, [prop]: event.target.value }));
  };

  const handleProductChange = (prop) => (event) => {
    setPurchaseBody((prev) => ({
      ...prev,
      product: {
        ...prev?.product,
        [prop]: event.target.value,
      },
    }));
  };

  const handleTransactionIdChange = (event) => {
    setPurchaseBody((prev) => ({
      ...prev,
      transactionId: event.target.value,
    }));
  };

  return (
    <Box
      sx={{
        p: 4,
        border: "1px solid #ccc",
        borderRadius: 1,
        width: "100%",
        maxWidth: 500,
        margin: "auto",
        mt: 4,
      }}
    >
      <Typography variant="h4" component="div" sx={{ mb: 4 }}>
        {sdkName}
      </Typography>

      {!isInit && (
        <TextField
          fullWidth
          label="Advertiser ID"
          variant="outlined"
          value={advertiserId}
          onChange={(e) => setAdvertiserId(e.target.value)}
          sx={{ mb: 2 }}
        />
      )}

      {isInit && (
        <Typography sx={{ mb: 2 }}>Inited with ID: {advertiserId}</Typography>
      )}

      {!isInit && (
        <Button
          variant="contained"
          onClick={initOnClick}
          fullWidth
          sx={{ mb: 2 }}
          color="error"
        >
          Init (필수 1회)
        </Button>
      )}

      {isInit && (
        <>
          <Divider sx={{ my: 2 }} />

          <Button
            variant="contained"
            onClick={pageViewOnClick}
            fullWidth
            sx={{ mb: 2 }}
          >
            Test Page View
          </Button>

          <Divider sx={{ my: 2 }} />

          <Stack spacing={2} sx={{ mb: 2 }}>
            <TextField
              fullWidth
              label="Product ID (ViewContent)"
              variant="outlined"
              value={viewContentBody.productId}
              onChange={handleChange(setViewContentBody)("productId")}
            />
            <TextField
              fullWidth
              label="Product Name (ViewContent)"
              variant="outlined"
              value={viewContentBody.productName}
              onChange={handleChange(setViewContentBody)("productName")}
            />
            <Button variant="contained" onClick={viewContentOnClick} fullWidth>
              Test View Content
            </Button>
          </Stack>

          <Divider sx={{ my: 2 }} />

          <Stack spacing={2}>
            <TextField
              fullWidth
              label="Product ID (AddToCart)"
              variant="outlined"
              value={addToCartBody.productId}
              onChange={handleChange(setAddToCartBody)("productId")}
            />
            <TextField
              fullWidth
              label="Product Name (AddToCart)"
              variant="outlined"
              value={addToCartBody.productName}
              onChange={handleChange(setAddToCartBody)("productName")}
            />
            <Button variant="contained" onClick={addToCartOnClick} fullWidth>
              Test Add To Cart
            </Button>
          </Stack>

          <Divider sx={{ my: 2 }} />

          <Stack spacing={2}>
            <TextField
              fullWidth
              label="Product ID (Purchase)"
              variant="outlined"
              value={purchaseBody.product?.productId || ""}
              onChange={handleProductChange("productId")}
            />
            <TextField
              fullWidth
              label="Product Name (Purchase)"
              variant="outlined"
              value={purchaseBody.product?.productName || ""}
              onChange={handleProductChange("productName")}
            />
            <TextField
              fullWidth
              label="Transaction ID (Purchase)"
              variant="outlined"
              value={purchaseBody.transactionId}
              onChange={handleTransactionIdChange}
            />
            <TextField
              fullWidth
              label="Price (Purchase)"
              variant="outlined"
              type="number"
              value={purchaseBody.product?.price || 0}
              onChange={handleProductChange("price")}
            />
            <TextField
              fullWidth
              label="Quantity (Purchase)"
              variant="outlined"
              type="number"
              value={purchaseBody.product?.quantity || 0}
              onChange={handleProductChange("quantity")}
            />
            <Button variant="contained" onClick={purchaseOnClick} fullWidth>
              Test Purchase
            </Button>
          </Stack>
        </>
      )}
    </Box>
  );
}

export default TestComponent;
