import "./App.css";
import OnWearTest from "./Components/OnWearTest";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PixelTest from "./Components/PixelTest";
import Home from "./Components/Home";
import FaceSwapTest from "./Components/FaceSwapTest";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/onwear" element={<OnWearTest />}></Route>
          <Route path="/pixel" element={<PixelTest />}></Route>
          <Route path="/faceswap" element={<FaceSwapTest />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
