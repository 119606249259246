import { Link } from "react-router-dom";
import { Button, Box } from "@mui/material";

const Home = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
        mt: 4,
      }}
    >
      <Button
        component={Link}
        to="/pixel"
        variant="contained"
        color="primary"
        sx={{
          width: "200px",
          height: "50px",
          fontSize: "16px",
        }}
      >
        픽셀테스트
      </Button>
      <Button
        component={Link}
        to="/onwear"
        variant="contained"
        color="secondary"
        sx={{
          width: "200px",
          height: "50px",
          fontSize: "16px",
        }}
      >
        OnWearTest
      </Button>
      <Button
        component={Link}
        to="/faceswap"
        variant="contained"
        color="secondary"
        sx={{
          width: "200px",
          height: "50px",
          fontSize: "16px",
        }}
      >
        Faceswap
      </Button>
    </Box>
  );
};

export default Home;
