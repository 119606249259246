import TestComponent from "./TestComponent";

const PixelTest = () => {
  return (
    <>
      <h1>Onout Pixel TestPage</h1>
      <TestComponent sdk="onoutLocalSDK" sdkName="Local" />
      <div style={{ margin: "100px" }}></div>
      <TestComponent sdk="onoutDevSDK" sdkName="Dev" />
      <div style={{ margin: "100px" }}></div>
      <TestComponent sdk="onoutSDK" sdkName="Prod (Not Yet)" />
    </>
  );
};

export default PixelTest;
